// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebaseConfig: {
    apiKey: "AIzaSyC8p_kdBKSs_-K3akI1nzjPfR-8r4ZilE4",
    authDomain: "heeg-545bb.firebaseapp.com",
    databaseURL: "https://heeg-545bb.firebaseio.com",
    projectId: "heeg-545bb",
    storageBucket: "heeg-545bb.appspot.com",
    messagingSenderId: "481053153768",
    appId: "1:481053153768:web:7a9361edc885d8fb79ec5d"
  },
  algolia: {
    KREDITOR_INDEX: 'kreditoren',
    CLIENT_INDEX: 'clients',
    APP_ID: 'B8TJXKDEY4',
    API_KEY: 'ea1a0a81a2a2e1a2a5a45d9932f66d59'
  },
  emulator: false
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
