import { shareReplay } from 'rxjs/operators';
import { Client } from './../models/client';
import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import firebase from 'firebase/app';
import { AngularFirestore } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class AuthService {

  user: firebase.User;
  user$: Observable<firebase.User> = null;
  dbUser: Client;
  role: any;
  constructor(private auth: AngularFireAuth, private router: Router, private db: AngularFirestore) {
    this.user$ = this.auth.authState.pipe(shareReplay(1));
    this.auth.onAuthStateChanged(async (user: firebase.User) => {
      if (!user) {
        this.user = null;
        console.log('Not logged In');
      } else {
        console.log(user)
        this.user = user;
        user.getIdTokenResult()
          .then(token => {
            this.role = token.claims['role'];
          })
      }
    })

  }

  public currentUser() {
    return this.auth.currentUser;
  }

  public setdisplayName(displayName: string) {
    return this.user.updateProfile({ displayName: displayName });
  }
  public applyVerification(actionCode: string) {
    return this.auth.applyActionCode(actionCode);
  }
  public async registerUser({ email, password }) {
    try {
      const userRef = await this.auth.createUserWithEmailAndPassword(email, password);
      userRef.user.sendEmailVerification();
      this.auth.signOut();
      return this.router.navigate(['/auth/login']);
    } catch (error) {
      console.log('ERROR ===>', error);
      return Promise.reject(error);
    }
  }


  public async getUserIdTokenResult(user: firebase.User) {
    const token = await user.getIdTokenResult(true);
    this.role = token.claims['role'];
    return this.role;
  }

  public sendVerificationEmail() {
    //const userRef = await this.auth.currentUser;
    return this.user.sendEmailVerification()
      .then(() => console.log('Email Verification sent!'))
      .catch(err => console.log('Email Verification Error', err))
  }

  public login(email: string, password: string): Promise<firebase.auth.UserCredential> {
    return this.auth.signInWithEmailAndPassword(email, password);
  }

  public logout() {
    return this.auth.signOut();
  }
  //Return Email
  public verifyPasswordResetCode(actionCode: string): Promise<string> {
    return this.auth.verifyPasswordResetCode(actionCode)
  }

  public resetPassword(actionCode: string, password: string) {
    return this.auth.confirmPasswordReset(actionCode, password);
  }

  public sendResetPasswordLink(email: string) {
    return this.auth.sendPasswordResetEmail(email);
  }
}
