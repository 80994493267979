import { QuillModule } from "ngx-quill";
import { ClientSearchComponent } from "./../../shared/components/client-search/client-search.component";
import { CommonModule, registerLocaleData } from "@angular/common";
import { NgAisModule } from "angular-instantsearch";
import { LOCALE_ID, NgModule } from "@angular/core";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatInputModule } from "@angular/material/input";
import localeDE from "@angular/common/locales/de";
import {
  FaConfig,
  FaIconLibrary,
  FontAwesomeModule,
} from "@fortawesome/angular-fontawesome";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { SweetAlert2Module } from "@sweetalert2/ngx-sweetalert2";
import { NgbModule, NgbTooltip } from "@ng-bootstrap/ng-bootstrap";
import { PdfViewerModule } from 'ng2-pdf-viewer';

registerLocaleData(localeDE);

@NgModule({
  imports: [
    CommonModule,
    NgAisModule.forRoot(),
    MatInputModule,
    MatAutocompleteModule,
    FontAwesomeModule,
    SweetAlert2Module,
    QuillModule.forRoot(),
    NgbModule,
    PdfViewerModule
  ],
  exports: [
    NgAisModule,
    MatInputModule,
    MatAutocompleteModule,
    ClientSearchComponent,
    FontAwesomeModule,
    SweetAlert2Module,
    QuillModule,
    NgbModule,
    PdfViewerModule
  ],
  declarations: [ClientSearchComponent],
  providers: [
    {
      provide: LOCALE_ID,
      useValue: "de-DE",
    },
  ],
})
export class SharedModule {
  constructor(library: FaIconLibrary, faConfig: FaConfig) {
    library.addIconPacks(fas);
    faConfig.defaultPrefix = "fas";
  }
}
