import { MenuItem } from './menu.model';

export const MENU: MenuItem[] = [
  {
    label: 'Main',
    isTitle: true
  },
  {
    label: 'Dashboard',
    icon: 'home',
    link: '/dashboard'
  },
  {
    label: 'Rechnungen',
    isTitle: true
  },
  {
    label: 'Übersicht',
    icon: 'star',
    link: '/invoices/overview',
  },
  {
    label: 'Einreichen',
    icon: 'plus',
    link: '/invoices/create'
  },
  {
    label: "Admin",
    isTitle: true,
    admin: true,
    employee: true,
  },
  {
    label: "Dashboard",
    icon: 'settings',
    admin: true,
    employee: true,
    link: '/admin/dashboard'
  },
  {
    label: "Kunden",
    icon: 'user',
    admin: true,
    employee: true,
    link: '/admin/clients'
  },
  {
    label: 'Nutzer',
    icon: 'users',
    admin: true,
    employee: false,
    link: '/admin/users'
  }
  
];
