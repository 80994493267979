import { ModalComponent } from './shared/components/modal/modal.component';
import { SharedModule } from "./core/shared/shared.module";
import { AngularFireAuthModule } from "@angular/fire/auth";
import { AngularFireStorageModule } from "@angular/fire/storage";
import { AngularFirestoreModule } from "@angular/fire/firestore";
import { AngularFireAuthGuardModule } from "@angular/fire/auth-guard";
import { environment } from "./../environments/environment";
import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";

import { AppRoutingModule } from "./app-routing.module";

import { LayoutModule } from "./views/layout/layout.module";
import { AuthGuard } from "./core/guard/auth.guard";

import { AppComponent } from "./app.component";
import { ErrorPageComponent } from "./views/pages/error-page/error-page.component";

import { HIGHLIGHT_OPTIONS } from "ngx-highlightjs";
import { AngularFireModule } from "@angular/fire";
import { VerifyEmailComponent } from "./views/pages/verify-email/verify-email.component";

import { USE_EMULATOR as USE_AUTH_EMULATOR } from "@angular/fire/auth";
import { USE_EMULATOR as USE_DATABASE_EMULATOR } from "@angular/fire/database";
import { USE_EMULATOR as USE_FIRESTORE_EMULATOR } from "@angular/fire/firestore";
import {
  REGION,
  USE_EMULATOR as USE_FUNCTIONS_EMULATOR,
} from "@angular/fire/functions";

@NgModule({
  declarations: [AppComponent, ErrorPageComponent, VerifyEmailComponent, ModalComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFireAuthModule,
    AngularFireStorageModule,
    AngularFirestoreModule,
    AngularFireAuthGuardModule,
    BrowserAnimationsModule,
    LayoutModule,
    SharedModule
  ],
  exports: [ModalComponent],
  providers: [
    AuthGuard,
    { provide: REGION, useValue: "europe-west3" },
    //{ provide: USE_AUTH_EMULATOR, useValue: environment.emulator ? ['localhost', 9099] : undefined },
    //{ provide: USE_FIRESTORE_EMULATOR, useValue: environment.emulator ? ['localhost', 8080] : undefined },
    //{ provide: USE_FUNCTIONS_EMULATOR, useValue: environment.emulator ? ['localhost', 5001] : undefined },
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
