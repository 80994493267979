import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss']
})
export class ModalComponent implements OnInit {

  @Input() public downloadUrl;

  public src;
  public rotation = 0;
  public zoom = 1;

  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit(): void {
    //this.src.url = this.downloadUrl;


    const xhr = new XMLHttpRequest();
    xhr.responseType = 'blob';
    xhr.onload = (event) => {
      //const blob = xhr.response;
      const blob = new Blob([xhr.response], { type: 'application/pdf' });
      this.src = URL.createObjectURL(blob);
    };
    xhr.open('GET', this.downloadUrl);
    xhr.send();
  }


  rotate(angle: number) {
    this.rotation += angle;
  }

  zoomIt(zoom: number) {
    this.zoom += zoom;
  }
}
