import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { BaseComponent } from "./views/layout/base/base.component";
import { ErrorPageComponent } from "./views/pages/error-page/error-page.component";
import {
  canActivate,
  customClaims,
  redirectUnauthorizedTo,
} from "@angular/fire/auth-guard";
import { pipe } from "rxjs";
import { map } from "rxjs/operators";
import { environment } from "src/environments/environment";

const adminOnly = () =>
  pipe(
    customClaims,
    map((claims) => {
      return claims.role === "admin" || claims.role === "employee"
        ? true
        : ["dashboard"];
    })
  );
const verifiedAndSignedInOnly = () =>
  map((user: firebase.default.User) => {
    if (!user) {
      return ["auth/login"];
    }
    if (!user.displayName) {
      return ["account/detail"];
    }
    return user.emailVerified === true || environment.emulator
      ? true
      : ["auth/verify"];
  });
const redirectUnauthorizedToLogin = () =>
  redirectUnauthorizedTo(["auth/login"]);

const routes: Routes = [
  {
    path: "auth",
    loadChildren: () =>
      import("./views/pages/auth/auth.module").then((m) => m.AuthModule),
  },
  {
    path: "",
    component: BaseComponent,
    ...canActivate(redirectUnauthorizedToLogin),
    children: [
      {
        path: "dashboard",
        ...canActivate(verifiedAndSignedInOnly),
        loadChildren: () =>
          import("./views/pages/dashboard/dashboard.module").then(
            (m) => m.DashboardModule
          ),
      },
      {
        path: "invoices",
        ...canActivate(verifiedAndSignedInOnly),
        loadChildren: () =>
          import("./views/pages/invoices/invoices.module").then(
            (m) => m.InvoicesModule
          ),
      },
      {
        path: "admin",
        ...canActivate(adminOnly),
        loadChildren: () =>
          import("./views/pages/admin/admin.module").then((m) => m.AdminModule),
      },
      {
        path: "account",
        //...canActivate(redirectUnauthorizedToLogin),
        loadChildren: () =>
          import("./views/pages/account/account.module").then(
            (m) => m.AccountModule
          ),
      },
      { path: "", redirectTo: "dashboard", pathMatch: "full" },
      //{ path: '**', redirectTo: 'dashboard', pathMatch: 'full' }
    ],
  },
  {
    path: "error",
    component: ErrorPageComponent,
    data: {
      type: 404,
      title: "Page Not Found",
      desc: "Oopps!! The page you were looking for doesn't exist.",
    },
  },
  {
    path: "error/:type",
    component: ErrorPageComponent,
  },
  { path: "**", redirectTo: "error", pathMatch: "full" },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: "top",
      onSameUrlNavigation: "reload",
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
