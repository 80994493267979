import { AuthService } from './../../../services/auth.service';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-verify-email',
  templateUrl: './verify-email.component.html',
  styleUrls: ['./verify-email.component.scss']
})
export class VerifyEmailComponent implements OnInit {

  constructor(private route: ActivatedRoute, private auth: AuthService, private router: Router) { 
    this.route.queryParamMap.subscribe(async query => {
      if(query.get('oobCode')) {
        this.auth.applyVerification(query.get('oobCode'))
        .then(async () => {
          await this.router.navigate(['dashboard']);
        })
        .catch(async err => {
          console.log('ERROR VERIFY', err);
          await this.auth.logout();
          await this.router.navigate(['auth/login'])
        })
      } else {
        await this.auth.logout();
        await this.router.navigate(['auth/login']);
      }
      
    })
  }

  ngOnInit(): void {
  }

}
